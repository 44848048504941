import { Filter } from '@autone/openapi-rebalancing';

import { RECEIVING } from '../../../features/edit-rebalancing/location-view/location-sidepanel/constants';
import { LocationDirectionType } from '../../../features/edit-rebalancing/location-view/location-sidepanel/types';

export const addProductToFromLocationToFilters = ({
  activeFilters,
  productId,
  locationId,
  locationDirection,
}: {
  activeFilters: Filter[];
  productId: string;
  locationId: string;
  locationDirection: LocationDirectionType;
}) => {
  const toOrFromLocation =
    locationDirection === RECEIVING ? 'from_location_id' : 'to_location_id';

  return activeFilters
    .filter(
      ({ dimension_id: dimensionId }) =>
        dimensionId !== 'product_id' && dimensionId !== 'from_location_id',
    )
    .concat([
      {
        name: 'Product',
        dimension_title: 'product_id',
        dimension_id: 'product_id',
        options: [{ id: productId, title: productId }],
      },
      {
        name: 'From Location',
        dimension_title: toOrFromLocation,
        dimension_id: toOrFromLocation,
        options: [{ id: locationId, title: locationId }],
      },
    ]);
};

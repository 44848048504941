import { AuthGuard, REBALANCING_PATH_APP } from '@autone/ui';
import { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import { DashboardLayout } from '../layout';

// ---------------------- App Routes --------------- //

const AppRoutes = {
  // AuthGuard checks if user has currently logged in or not when a user accesses any given page
  guard: AuthGuard,
  layout: DashboardLayout,
  routes: [
    // an overview of all current and historic rebalancings
    {
      exact: true,
      path: REBALANCING_PATH_APP.overview,
      component: lazy(() => import('../views/Overview')),
    },
    // edit a specific batch
    {
      exact: true,
      path: REBALANCING_PATH_APP.editRebalancing,
      component: lazy(() => import('../views/EditRebalancing')),
    },
    {
      exact: true,
      path: REBALANCING_PATH_APP.confirmedRebalancing,
      component: lazy(() => import('../views/SubmissionConfirmation')),
    },
    {
      exact: true,
      path: '/',
      component: () => <Redirect to={REBALANCING_PATH_APP.overview} />,
    },
    // if path is not recognised then redirect back to the homepage
    {
      component: () => <Redirect to={REBALANCING_PATH_APP.overview} />,
    },
  ],
};

export default AppRoutes;

import {
  Filter,
  GetProposalLocationDrilldownResponse,
  LocationDrilldownProposal,
  LocationPage,
  LocationProposalV2,
  LocationReceivingSkuProposal,
  LocationSendingSkuProposal,
  LocationSkusReceivingPage,
  LocationSkusSendingPage,
} from '@autone/openapi-rebalancing';
import { encodeUrl, ExtendedCustomBaseQueryType } from '@autone/ui';

import { LocationDirectionType } from '../../../features/edit-rebalancing/location-view/location-sidepanel/types';
import {
  isReceivingLocation,
  isReceivingLocationArray,
  isSendingLocation,
  isSendingLocationArray,
} from '../../../utils';
import { addProductToFilters } from '../../../utils/features/products/addProductToFilters';
import { locationsDetailApi } from '../../services/edit-rebalancing/locationsDetail';
import { locationsApi } from '../../services/edit-rebalancing/locationsPaged';
import type { AppDispatch } from '../../store.types';

export const updateSharedAggLocationFields = (
  cacheItem: LocationProposalV2,
  foundLocation: LocationProposalV2,
) => {
  cacheItem.enabled = foundLocation.enabled;
  cacheItem.receiving_quantity = foundLocation.receiving_quantity;
  cacheItem.sending_quantity = foundLocation.sending_quantity;
  cacheItem.understock_units_solved = foundLocation.understock_units_solved;
  cacheItem.sales_uplift = foundLocation.sales_uplift;
  cacheItem.unique_receiving_locations =
    foundLocation.unique_receiving_locations;
  cacheItem.unique_sending_locations = foundLocation.unique_sending_locations;
};

export const updateSharedLocationDetailFields = (
  cacheItem: LocationReceivingSkuProposal | LocationSendingSkuProposal,
  foundProductSku: LocationReceivingSkuProposal | LocationSendingSkuProposal,
) => {
  cacheItem.enabled = foundProductSku.enabled;
  cacheItem.location_inventory = foundProductSku.location_inventory;
  cacheItem.post_transfer_coverage_weeks =
    foundProductSku.post_transfer_coverage_weeks;
  cacheItem.target_coverage_weeks = foundProductSku.target_coverage_weeks;
};

export const updatedSharedLocationDrilldownFields = (
  cacheItem: LocationDrilldownProposal,
  foundProductSkuLocation: LocationDrilldownProposal,
) => {
  cacheItem.enabled = foundProductSkuLocation.enabled;
  cacheItem.user_quantity = foundProductSkuLocation.user_quantity;
  cacheItem.to_location_all_trips_quantity =
    foundProductSkuLocation.to_location_all_trips_quantity;
  cacheItem.to_location_all_trips_coverage =
    foundProductSkuLocation.to_location_all_trips_coverage;
  cacheItem.to_location_target_coverage =
    foundProductSkuLocation.to_location_target_coverage;
  cacheItem.from_location_all_trips_quantity =
    foundProductSkuLocation.from_location_all_trips_quantity;
  cacheItem.from_location_post_transfer_coverage =
    foundProductSkuLocation.from_location_post_transfer_coverage;
  cacheItem.from_location_target_coverage =
    foundProductSkuLocation.from_location_target_coverage;
};

export const updateLocationAggregateCache = async ({
  customBaseQuery,
  dispatch,
  batchId,
  filters,
  rowsPerPage,
  serverSort,
  locationSearchTerm,
  locationId,
}: {
  customBaseQuery: ExtendedCustomBaseQueryType<LocationPage>;
  dispatch: AppDispatch;
  batchId: string;
  filters: Filter[];
  rowsPerPage: number;
  serverSort: string;
  locationSearchTerm: string;
  locationId: string;
}) => {
  const postResponse = await customBaseQuery({
    url: encodeUrl({
      url: `v2/batch/{batchId}/proposal/locations?limit={limit}&sort_by={sortBy}`,
      variables: {
        batchId,
        // arbitrary large number to get all the data
        limit: `${10000000}`,
        sortBy: serverSort,
      },
    }),
    method: 'POST',
    body: {
      filters,
      locations_related_to: locationId,
    },
  });

  const { data, error } = postResponse;

  if (error) {
    return { error };
  }

  const { items: locations } = data;

  return dispatch(
    locationsApi.util.updateQueryData(
      'getPagedLocations',
      {
        batchId,
        filters,
        sortBy: serverSort,
        cursor: null,
        limit: rowsPerPage,
        locationSearchTerm,
      },
      (draft) => {
        draft.items.forEach((draftItem) => {
          const foundLocation = locations.find(
            (item) => item.location.id === draftItem.location.id,
          );

          if (foundLocation) {
            updateSharedAggLocationFields(draftItem, foundLocation);
          }
        });
      },
    ),
  );
};

export const updateLocationDetailCache = async ({
  customBaseQuery,
  dispatch,
  batchId,
  locationId,
  locationDirection,
  filters,
  rowsPerPage,
  serverSort,
  productSearchTerm,
  includeNilTransfers,
  productId,
}: {
  customBaseQuery: ExtendedCustomBaseQueryType<
    LocationSkusSendingPage | LocationSkusReceivingPage
  >;
  dispatch: AppDispatch;
  batchId: string;
  filters: Filter[];
  locationId: string;
  locationDirection: LocationDirectionType;
  rowsPerPage: number;
  serverSort: string;
  productSearchTerm: string;
  includeNilTransfers: boolean;
  productId: string;
}) => {
  const postResponse = await customBaseQuery({
    url: encodeUrl({
      url: `v2/batch/{batchId}/proposal/locations/{locationId}/skus?drilldown={locationDirection}&limit={limit}&sort_by={sortBy}`,
      variables: {
        batchId,
        locationId,
        locationDirection,
        // arbitrary large number to get all the data
        limit: `1000000`,
        sortBy: serverSort,
      },
    }),
    method: 'POST',
    body: {
      filters: addProductToFilters({ activeFilters: filters, productId }),
      product_search_term: productSearchTerm,
      include_nil_transfers: includeNilTransfers,
    },
  });

  const { data, error } = postResponse;

  if (error) {
    return { error };
  }

  const { items: productSkus } = data;

  return dispatch(
    locationsDetailApi.util.updateQueryData(
      'getPagedLocationSkus',
      {
        batchId,
        locationId,
        locationDirection,
        filters,
        sortBy: serverSort,
        cursor: null,
        limit: rowsPerPage,
        productSearchTerm,
        includeNilTransfers,
      },
      (draft) => {
        draft.items.forEach((draftItem) => {
          if (
            isReceivingLocationArray(productSkus) &&
            isReceivingLocation(draftItem)
          ) {
            const foundProductSku = productSkus.find(
              (item) => item.sku.sku_id === draftItem.sku.sku_id,
            );

            if (foundProductSku) {
              updateSharedLocationDetailFields(draftItem, foundProductSku);
              draftItem.receiving_quantity = foundProductSku.receiving_quantity;
            }
          }

          if (
            isSendingLocationArray(productSkus) &&
            isSendingLocation(draftItem)
          ) {
            const foundProductSku = productSkus.find(
              (item) => item.sku.sku_id === draftItem.sku.sku_id,
            );

            if (foundProductSku) {
              updateSharedLocationDetailFields(draftItem, foundProductSku);
              draftItem.sending_quantity = foundProductSku.sending_quantity;
            }
          }
        });
      },
    ),
  );
};

export const updateLocationDrilldownCache = async ({
  customBaseQuery,
  dispatch,
  batchId,
  filters,
  activeFilters,
  locationId,
  locationDirection,
  productId,
  skuId,
}: {
  customBaseQuery: ExtendedCustomBaseQueryType<GetProposalLocationDrilldownResponse>;
  dispatch: AppDispatch;
  batchId: string;
  filters: Filter[];
  activeFilters: Filter[];
  locationId: string;
  locationDirection: LocationDirectionType;
  productId: string;
  skuId: string;
}) => {
  const postResponse = await customBaseQuery({
    url: encodeUrl({
      url: `batch/{batchId}/proposal/locations/{locationId}?drilldown={locationDirection}`,
      variables: {
        batchId,
        locationId,
        locationDirection,
      },
    }),
    method: 'POST',
    body: {
      filters: activeFilters,
    },
  });

  const { data, error } = postResponse;

  if (error) {
    return { error };
  }

  const { proposals: locationSku } = data;

  return dispatch(
    locationsDetailApi.util.updateQueryData(
      'getLocationDetailDrilldown',
      { batchId, locationId, productId, locationDirection, filters, skuId },
      (draft) => {
        draft.forEach((draftItem) => {
          const foundProductSkuLocation = locationSku.find(
            (item) =>
              item.location.location_id === draftItem.location.location_id &&
              item.sku.sku_id === draftItem.sku.sku_id,
          );

          if (foundProductSkuLocation) {
            updatedSharedLocationDrilldownFields(
              draftItem,
              foundProductSkuLocation,
            );
          }
        });
      },
    ),
  );
};

import {
  EditActiveResponse,
  Filter,
  TripPage,
} from '@autone/openapi-rebalancing';
import {
  encodeUrl,
  ExtendedCustomBaseQueryType,
  QueryReturnValue,
} from '@autone/ui';

import { EDIT_REBALANCING_TAGS } from '../../../constant';
import { updatedAggregatedTripCache } from '../../helpers';
import { handleToggleEnable } from '../../helpers/shared/handleToggleEnable';
import { rebalancingApi } from '../rebalancing';

export const tripsApi = rebalancingApi.injectEndpoints({
  endpoints: (builder) => ({
    getPagedTrips: builder.query<
      TripPage,
      {
        batchId: string;
        filters: Filter[];
        sortBy: string;
        cursor: string | null;
        limit: number;
        locationSearchTerm: string;
      }
    >({
      providesTags: ['TripsPaged'],
      query: ({
        batchId,
        filters,
        sortBy,
        cursor,
        limit,
        locationSearchTerm,
      }) => ({
        url: encodeUrl({
          url: `v2/batch/{batchId}/proposal/trips?${
            cursor ? `cursor={cursor}&` : ''
          }limit={limit}&sort_by={sortBy}`,
          variables: {
            batchId,
            cursor: cursor || '',
            limit: `${limit}`,
            sortBy,
          },
        }),
        method: 'POST',
        body: { filters, location_search_term: locationSearchTerm },
      }),
    }),
    patchPagedTripStatus: builder.mutation<
      QueryReturnValue,
      {
        batchId: string;
        fromLocationId: string;
        toLocationId: string;
        filters: Filter[];
        enabled: boolean;
        sortBy: string;
        limit: number;
        locationSearchTerm: string;
      }
    >({
      invalidatesTags: EDIT_REBALANCING_TAGS.filter(
        (tag) => tag !== 'TripsPaged',
      ),
      async queryFn(
        {
          batchId,
          fromLocationId,
          toLocationId,
          filters,
          enabled,
          sortBy,
          limit,
          locationSearchTerm,
        },
        { dispatch },
        _extraOptions,
        customBaseQuery:
          | ExtendedCustomBaseQueryType<EditActiveResponse>
          | ExtendedCustomBaseQueryType<TripPage>,
      ) {
        const { error } = await handleToggleEnable({
          customBaseQuery:
            customBaseQuery as ExtendedCustomBaseQueryType<EditActiveResponse>,
          batchId,
          body: {
            filters,
            enabled,
            from_location_id: fromLocationId,
            to_location_id: toLocationId,
          },
        });

        if (error) {
          return { error } as any;
        }

        await updatedAggregatedTripCache({
          customBaseQuery:
            customBaseQuery as ExtendedCustomBaseQueryType<TripPage>,
          dispatch,
          batchId,
          filters,
          fromLocationId,
          toLocationId,
          rowsPerPage: limit,
          serverSort: sortBy,
          aggregateTableSearch: locationSearchTerm,
        });

        return { data: null, error: null } as any;
      },
    }),
  }),
});

export const {
  useGetPagedTripsQuery,
  useLazyGetPagedTripsQuery,
  usePatchPagedTripStatusMutation,
} = tripsApi;

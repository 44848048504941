import {
  EditActiveResponse,
  Filter,
  GetLocationKPIsRequest,
  GetLocationKPIsResponse,
  GetProposalLocationPivotRequest,
  GetProposalProductPeekViewResponse,
  LocationMetrics,
  LocationPivotPage,
  ProductPage,
  type ProductPeekViewLocation,
} from '@autone/openapi-rebalancing';
import {
  encodeUrl,
  ExtendedCustomBaseQueryType,
  QueryReturnValue,
} from '@autone/ui';

import { EDIT_REBALANCING_TAGS } from '../../../../constant';
import { updateProductAggregateCache } from '../../../helpers';
import { handleToggleEnable } from '../../../helpers/shared/handleToggleEnable';
import { rebalancingApi } from '../../rebalancing';

export const productsApi = rebalancingApi.injectEndpoints({
  endpoints: (builder) => ({
    getPivotKpis: builder.query<
      GetLocationKPIsResponse,
      {
        filters: GetLocationKPIsRequest['filters'];
        includeOutOfScope?: GetLocationKPIsRequest['include_out_of_scope'];
        advancedFilters: GetLocationKPIsRequest['advanced_filter'];
        aggregateBy: GetLocationKPIsRequest['advanced_filter_aggregation_level'];
        batchId: string;
        toLocationId: string;
      }
    >({
      providesTags: ['RebalPivotKpis'],
      // Make the stale time 1 second so that the fresh data can be fetched
      // this is important to avoid stale data when the user amends replen units
      // and then attempts to sort the rows (which should fetch fresh data)
      // more generally, the table is quite interactive
      // so having a short stale time is desired
      keepUnusedDataFor: 1,
      query: ({
        filters,
        advancedFilters,
        includeOutOfScope,
        aggregateBy,
        batchId,
        toLocationId,
      }) => ({
        url: encodeUrl({
          url: `/v2/batch/{batchId}/proposal/locations/{toLocationId}/kpis`,
          variables: {
            batchId,
            toLocationId,
          },
        }),
        method: 'POST',
        body: {
          filters,
          // Advanced filters and advanced_filter_aggregation_level
          // need to be passed as a pair, you can't pass one as null and the other with a value
          advanced_filter: advancedFilters || null,
          advanced_filter_aggregation_level: advancedFilters
            ? aggregateBy
            : null,
          include_out_of_scope: includeOutOfScope,
        },
      }),
    }),
    getPivot: builder.query<
      LocationPivotPage,
      {
        batchId: string;
        toLocationId: string;
        cursor: string | null;
        limit: number;
        searchTerm: string;
        includeOutOfScope: GetProposalLocationPivotRequest['include_out_of_scope'];
        filters: GetProposalLocationPivotRequest['filters'];
        advancedFilters: GetProposalLocationPivotRequest['advanced_filter'];
        // In the absence of query path parameter types we create a type
        sortBy: `${keyof LocationMetrics}:${'asc' | 'desc'}` | null;
        aggregateBy: GetLocationKPIsRequest['advanced_filter_aggregation_level'];
      }
    >({
      providesTags: ['RebalPivotTable'],
      // Make the stale time 1 second so that the fresh data can be fetched
      // this is important to avoid stale data when the user amends replen units
      // and then attempts to sort the rows (which should fetch fresh data)
      // more generally, this table is quite interactive
      // so having a short stale time is desired
      keepUnusedDataFor: 1,
      query: ({
        batchId,
        toLocationId,
        cursor,
        limit,
        aggregateBy,
        filters,
        advancedFilters,
        searchTerm,
        sortBy,
        includeOutOfScope,
      }) => ({
        url: encodeUrl({
          url: `/v2/batch/{batchId}/proposal/locations/{toLocationId}/pivot?limit={limit}${
            cursor ? `&cursor={cursor}` : ''
          }${sortBy ? `&sort_by={sortBy}` : ''}${
            aggregateBy ? `&aggregate_by={aggregateBy}` : ''
          }`,
          variables: {
            batchId,
            toLocationId,
            cursor: cursor || '',
            limit: limit.toString(),
            sortBy: sortBy || '',
            aggregateBy: aggregateBy || '',
          },
        }),
        method: 'POST',
        body: {
          filters,
          product_search_term: searchTerm,
          advanced_filter: advancedFilters,
          include_out_of_scope: includeOutOfScope,
        },
      }),
    }),
    getPagedProducts: builder.query<
      ProductPage,
      {
        batchId: string;
        filters: Filter[];
        productSearchTerm?: string;
        sortBy: string;
        cursor: string | null;
        limit: number;
      }
    >({
      providesTags: ['ProductsPaged'],
      query: ({
        batchId,
        filters,
        sortBy,
        cursor,
        limit,
        productSearchTerm,
      }) => ({
        url: encodeUrl({
          url: `v2/batch/{batchId}/proposal/products?${
            cursor ? `cursor={cursor}&` : ''
          }limit={limit}&sort_by={sortBy}`,
          variables: {
            batchId,
            cursor: cursor || '',
            limit: `${limit}`,
            sortBy,
          },
        }),
        method: 'POST',
        body: { filters, product_search_term: productSearchTerm },
      }),
    }),
    patchPagedProductStatus: builder.mutation<
      QueryReturnValue,
      {
        batchId: string;
        productId: string;
        filters: Filter[];
        enabled: boolean;
        sortBy: string;
        cursor: string | null;
        limit: number;
        productSearchTerm: string;
      }
    >({
      invalidatesTags: EDIT_REBALANCING_TAGS.filter(
        (tag) => tag !== 'ProductsPaged',
      ),
      async queryFn(
        {
          batchId,
          productId,
          filters,
          enabled,
          sortBy,
          cursor,
          limit,
          productSearchTerm,
        },
        { dispatch },
        _extraOptions,
        customBaseQuery:
          | ExtendedCustomBaseQueryType<EditActiveResponse>
          | ExtendedCustomBaseQueryType<ProductPage>,
      ) {
        const { error } = await handleToggleEnable({
          customBaseQuery:
            customBaseQuery as ExtendedCustomBaseQueryType<EditActiveResponse>,
          batchId,
          body: {
            filters,
            enabled,
            product_id: productId,
          },
        });

        if (error) {
          return { error } as any;
        }

        await updateProductAggregateCache({
          customBaseQuery:
            customBaseQuery as ExtendedCustomBaseQueryType<ProductPage>,
          dispatch,
          batchId,
          productId,
          filters,
          cursor,
          limit,
          sortBy,
          searchTerm: productSearchTerm,
        });

        return { data: null, error: null } as any;
      },
    }),
    getProposalProductPeakView: builder.query<
      ProductPeekViewLocation[],
      { productId: string; batchId: string }
    >({
      query: ({ productId, batchId }) => ({
        url: encodeUrl({
          url: `batch/{batchId}/proposal/products/{productId}/peek`,
          variables: {
            batchId,
            productId,
          },
        }),
        method: 'GET',
      }),
      transformResponse: (response: GetProposalProductPeekViewResponse) =>
        response.locations,
    }),
  }),
});

export const {
  useGetPivotKpisQuery,
  useGetPivotQuery,
  useGetPagedProductsQuery,
  useLazyGetPagedProductsQuery,
  usePatchPagedProductStatusMutation,
  useGetProposalProductPeakViewQuery,
} = productsApi;

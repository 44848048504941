import {
  Batch,
  BatchOverviewsPage,
  type BatchSchedule,
  BatchWithRebalanceNetwork,
  CreateBatchExportResponse,
  CreateBatchResponse,
  CreateBatchScheduleRequest,
  CreateBatchScheduleResponse,
  DeleteBatchScheduleResponse,
  GetBatchExportResponse,
  ListBatchOverviewResponse,
  PostBatchSubmissionResponse,
  rebalancing__batch__batch__routes__CreateBatchRequest,
} from '@autone/openapi-rebalancing';
import { CommonTranslationFunction } from '@autone/translations';
import { encodeUrl } from '@autone/ui';
import { enqueueSnackbar } from 'notistack';

import { rebalancingApi } from '..';
import { BatchQueryData } from '../../../features/overview-v2/tables/OverviewTable';

const batchesApis = rebalancingApi.injectEndpoints({
  endpoints: (builder) => ({
    getBatch: builder.query<
      BatchWithRebalanceNetwork,
      { batchId: string; extraArg?: string }
    >({
      query: ({ batchId }) => ({
        url: encodeUrl({ url: `/batch/{batchId}`, variables: { batchId } }),
      }),
      providesTags: ['Batch'],
      // we destructure response.batches here to avoid having to do it in the component
      transformResponse: (response: { batch: BatchWithRebalanceNetwork }) =>
        response.batch,
    }),
    getBatches: builder.query<ListBatchOverviewResponse, void>({
      query: () => `batch/overview`,
      providesTags: ['Batches'],
    }),
    patchBatch: builder.mutation<
      Batch,
      {
        batchId: Batch['id'];
        status?: Batch['status'];
        name?: Batch['name'];
        launchNewRebalOverview?: boolean;
        queryParams: BatchQueryData | null;
      }
    >({
      invalidatesTags: ['Batch'],
      query: ({ batchId, status, name }) => {
        return {
          url: encodeUrl({ url: `/batch/{batchId}`, variables: { batchId } }),
          method: 'PATCH',
          body: {
            batch: {
              status,
              name,
            },
          },
        };
      },
      async onQueryStarted(
        { batchId, status, name, launchNewRebalOverview, queryParams },
        { dispatch, queryFulfilled },
      ) {
        let patchResult;

        if (launchNewRebalOverview && queryParams) {
          if (name) {
            patchResult = dispatch(
              batchesApis.util.updateQueryData(
                'getBatchOverviewsPaged',
                queryParams,
                (draft) => {
                  draft.items.forEach((item) => {
                    if (item.batch.id === batchId) {
                      if (name) {
                        item.batch.name = name;
                      }
                    }
                  });
                },
              ),
            );
          } else if (status === 'deleted' || status === 'ready') {
            patchResult = dispatch(
              batchesApis.util.updateQueryData(
                'getBatchOverviewsPaged',
                queryParams,
                (draft) => {
                  draft.items = draft.items.filter(
                    (item) => item.batch.id !== batchId,
                  );
                },
              ),
            );
          }
        }
        //TODO: remove once we have migrated fully to new version
        else {
          patchResult = dispatch(
            batchesApis.util.updateQueryData(
              'getBatches',
              undefined,
              (draft) => {
                draft.batches.forEach((item) => {
                  if (item.batch.id === batchId) {
                    if (status) {
                      item.batch.status = status;
                    }
                    if (name) {
                      item.batch.name = name;
                    }
                  }
                });
              },
            ),
          );
        }

        try {
          await queryFulfilled;
        } catch {
          patchResult?.undo();
        }
      },

      transformResponse: (response: { batch: Batch }) => response.batch,
    }),
    createBatch: builder.mutation<
      CreateBatchResponse,
      rebalancing__batch__batch__routes__CreateBatchRequest
    >({
      query(body) {
        return {
          url: '/batch',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['NetworkTrips'],
    }),
    createBatchSchedule: builder.mutation<
      CreateBatchScheduleResponse,
      CreateBatchScheduleRequest
    >({
      invalidatesTags: ['Batches'],
      query: (body) => ({
        url: `/batch/schedule`,
        method: 'POST',
        body,
        extraArgs: { hideSnackbar: true },
      }),
    }),
    submitBatch: builder.mutation<
      PostBatchSubmissionResponse,
      { batchId: string; recipientIds: string[] | undefined }
    >({
      query: ({ batchId, recipientIds }) => ({
        url: encodeUrl({
          url: `/batch/{batchId}/submission`,
          variables: { batchId },
        }),
        method: 'POST',
        body: {
          recipient_ids: recipientIds,
        },
      }),
      invalidatesTags: ['Batches', 'Batch'],
    }),
    deleteBatch: builder.mutation<
      void,
      {
        batchId: string;
        submissionId: string;
        recipientIds: string[] | undefined;
      }
    >({
      query: ({ batchId, submissionId, recipientIds }) => ({
        url: encodeUrl({
          url: `/batch/{batchId}/submission/{submissionId}`,
          variables: { batchId, submissionId },
        }),
        method: 'DELETE',
        body: {
          recipient_ids: recipientIds,
        },
      }),
      invalidatesTags: ['Batches', 'Batch'],
    }),
    deleteSchedule: builder.mutation<
      DeleteBatchScheduleResponse,
      { scheduleId: string }
    >({
      invalidatesTags: ['Batch', 'Batches'],
      query: ({ scheduleId }) => ({
        url: encodeUrl({
          url: `batch/schedule/{scheduleId}`,
          variables: { scheduleId },
        }),
        method: 'DELETE',
      }),
    }),
    getBatchOverviewsPaged: builder.query<
      BatchOverviewsPage,
      {
        batchStatuses: Batch['status'][];
        sortBy: string;
        limit: number;
        cursor: string | null;
        searchTerm: string | null;
      }
    >({
      providesTags: ['Batches'],
      query: ({ batchStatuses, sortBy, limit, cursor, searchTerm }) => {
        const batchStatusesQueryString = batchStatuses
          .map((status) => `batch_statuses=${status}`)
          .join('&');

        return {
          url: encodeUrl({
            url: `/v2/batch/overview?${batchStatusesQueryString}&limit={limit}&sort_by={sortBy}${
              cursor ? `&cursor={cursor}` : ''
            }${searchTerm ? `&batch_name_search_term={searchTerm}` : ''}`,
            variables: {
              limit: limit.toString(),
              sortBy,
              searchTerm: searchTerm || '',
              cursor: cursor || '',
            },
          }),
          method: 'POST',
        };
      },
    }),
    getScheduledBatches: builder.query<BatchSchedule[], void>({
      providesTags: ['Batches'],
      query: () => ({
        url: '/batch/overview/scheduled',
      }),
      transformResponse: ({ items }: { items: BatchSchedule[] }) => items,
    }),
    createBatchExport: builder.query<
      CreateBatchExportResponse,
      { batchId: string }
    >({
      query: ({ batchId }) => ({
        url: encodeUrl({
          url: `/v2/batch/{batchId}/export`,
          variables: { batchId },
        }),
        method: 'POST',
        body: {},
      }),
    }),
    getBatchExport: builder.query<
      GetBatchExportResponse,
      { batchId: string; exportId: string }
    >({
      query: ({ batchId, exportId }) => ({
        url: encodeUrl({
          url: `/v2/batch/{batchId}/export/{exportId}`,
          variables: { batchId, exportId },
        }),
        method: 'GET',
      }),
    }),
    archiveBatch: builder.mutation<
      null,
      {
        batchId: string;
        common: CommonTranslationFunction;
        batchQueryData: BatchQueryData | null;
      }
    >({
      query: ({ batchId }) => ({
        url: encodeUrl({
          url: '/batch/{batchId}/archive',
          variables: { batchId },
        }),
        method: 'POST',
      }),
      async onQueryStarted(
        { batchId, common, batchQueryData },
        { dispatch, queryFulfilled },
      ) {
        if (!batchQueryData) return;
        const patchResult = dispatch(
          batchesApis.util.updateQueryData(
            'getBatchOverviewsPaged',
            batchQueryData,
            (draft) => {
              draft.items = draft.items.filter(
                (item) => item.batch.id !== batchId,
              );
            },
          ),
        );
        try {
          await queryFulfilled;
          enqueueSnackbar(common('archive-batches.snackbar-text'), {
            variant: 'success',
          });
        } catch {
          patchResult?.undo();
        }
      },
    }),
  }),
});

export const {
  useDeleteBatchMutation,
  useSubmitBatchMutation,
  useCreateBatchMutation,
  useCreateBatchScheduleMutation,
  useGetBatchQuery,
  useLazyGetBatchQuery,
  useGetBatchesQuery,
  usePatchBatchMutation,
  useDeleteScheduleMutation,
  useGetBatchOverviewsPagedQuery,
  useGetScheduledBatchesQuery,
  useLazyCreateBatchExportQuery,
  useGetBatchExportQuery,
  useArchiveBatchMutation,
} = batchesApis;

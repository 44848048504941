import { AutoneTranslation, i18nAutone } from '@autone/translations';
import {
  AppShell,
  ErrorFallback,
  useAppSettings,
  useGetUserConfigQuery,
  usePriceTypeOptions,
} from '@autone/ui';
import {
  REBALANCING_ADMIN,
  REBALANCING_READONLY,
  REBALANCING_STANDARD,
} from '@autone/utils';
import { ErrorBoundary } from 'react-error-boundary';

import { APP_NAME } from './constant';
import { store } from './redux/store';
import AppRoutes from './routes/app.routes';
import ErrorRoute from './routes/error.route';
import LoadingRoute from './routes/loading.route';
import NewRebalanceRoute from './routes/new-rebalance.route';

function App() {
  const routes = [ErrorRoute, LoadingRoute, NewRebalanceRoute, AppRoutes];

  // set up app settings - intercom, sentry, mixpanel & fullview
  useAppSettings(APP_NAME);

  // push the price type options and defaults into redux
  usePriceTypeOptions();

  // get the user config - this gets data from core/user/me and populates redux store
  useGetUserConfigQuery();

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <AutoneTranslation namespace={APP_NAME} i18n={i18nAutone}>
        <AppShell
          reduxStore={store}
          routes={routes}
          permissionGroups={[
            REBALANCING_ADMIN,
            REBALANCING_STANDARD,
            REBALANCING_READONLY,
          ]}
        />
      </AutoneTranslation>
    </ErrorBoundary>
  );
}

export default App;

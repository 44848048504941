import {
  REBALANCING_ADMIN,
  REBALANCING_READONLY,
  REBALANCING_STANDARD,
} from '@autone/utils';

export * from './rebalancingTags';

export const APP_NAME = 'rebalancing';
export const INITIAL_NUMBER_OF_ROWS_PER_PAGE = 50;
export const DEFAULT_SORT_COLUMN = 'sales_uplift';
export const DEFAULT_SORT_ORDER = 'desc';
export const LOCATION_CELL_WIDTH = 140;
export const PRODUCT_DRILLDOWN_TABLE_ENDPOINTS = [
  'patchProductDetailDrilldownQuantity',
  'patchProductDetailDrilldownStatus',
];
export const PRODUCT_DETAIL_TABLE_ENDPOINTS = [
  'patchProductSendingLocationSkusStatus',
];
export const MAX_REBAL_NAME_CHARACTERS = 25;
export const DEFAULT_MINIMUM_SKU_LIFE = 30;
export const DEFAULT_SHIPPING_TIME = 7;
export const DEFAULT_WEEKS_EXCESS_FOR_CLEAN_UP = 1;
export const DEFAULT_SCOPE = {
  shipping_time_days: DEFAULT_SHIPPING_TIME,
  minimum_sku_life_days: DEFAULT_MINIMUM_SKU_LIFE,
  save_size_run: false,
};
export const PRODUCTS_IN_SCOPE_ERROR_KEY = 'products_in_scope';
export const EMPTY_WEEKS_ABOVE_EXCESS = 'empty_weeks_above_excess';
export const DEFAULT_NETWORK_LEVEL = 'region';
export const REBALANCING_PERMISSIONS = [
  REBALANCING_ADMIN,
  REBALANCING_READONLY,
  REBALANCING_STANDARD,
];
export const TO_LOCATION_LIMIT = 1;

import { AuthGuard, ErrorView, REBALANCING_PATH_APP } from '@autone/ui';

import { PageLayout } from '../layout';

const ErrorRoute = {
  guard: AuthGuard,
  layout: PageLayout,
  path: '/rebalancing/error',
  routes: [
    {
      exact: true,
      path: REBALANCING_PATH_APP.error,
      component: () => <ErrorView module={'rebalancing'} />,
    },
  ],
};

export default ErrorRoute;

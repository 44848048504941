import {
  EditActiveResponse,
  Filter,
  GetTripDrilldownResponse,
  TripPage,
} from '@autone/openapi-rebalancing';
import {
  encodeUrl,
  errorSnackBar,
  ExtendedCustomBaseQueryType,
} from '@autone/ui';
import { enqueueSnackbar } from 'notistack';

import { EDIT_REBALANCING_TAGS } from '../../../constant/rebalancingTags';
import { EnrichedTripDrilldownProposal } from '../../../types';
import { addProductToFilters } from '../../../utils/features/products/addProductToFilters';
import { updatedAggregatedTripCache } from '../../helpers';
import { handleToggleEnable } from '../../helpers/shared/handleToggleEnable';
import { rebalancingApi } from '../rebalancing';

export const tripsDrillApi = rebalancingApi.injectEndpoints({
  endpoints: (builder) => ({
    getTripsDrilldown: builder.query({
      query: ({
        batchId,
        fromLocationId,
        toLocationId,
        filters,
      }: {
        batchId: string;
        fromLocationId: string;
        toLocationId: string;
        filters: Filter[];
      }) => ({
        url: encodeUrl({
          url: `batch/{batchId}/proposal/trips/{fromLocationId}/{toLocationId}`,
          variables: { batchId, fromLocationId, toLocationId },
        }),
        method: 'POST',
        body: { filters },
      }),
      transformResponse: (response: {
        proposals: EnrichedTripDrilldownProposal[];
      }) => {
        response.proposals.forEach((item: EnrichedTripDrilldownProposal) => {
          item.to_location_all_trips_coverage_sort =
            item.to_location_all_trips_coverage;
          item.from_location_inventory_after_rebalance_sort =
            item.from_location_inventory_after_rebalance;
          item.to_location_inventory_after_rebalance_sort =
            item.to_location_inventory_after_rebalance;
          item.user_quantity_sort = item.user_quantity;
        });

        return response.proposals;
      },
      providesTags: ['TripsDrilldown'],
    }),
    patchTripDrilldownStatus: builder.mutation({
      invalidatesTags: EDIT_REBALANCING_TAGS.filter(
        (tag) => tag !== 'TripsPaged' && tag !== 'TripsDrilldown',
      ),
      async queryFn(
        {
          batchId,
          productId,
          filters,
          enabled,
          fromLocationId,
          toLocationId,
          skuId,
          rowsPerPage,
          serverSort,
          aggregateTableSearch,
        },
        { dispatch },
        _extraOptions,
        customBaseQuery:
          | ExtendedCustomBaseQueryType<EditActiveResponse>
          | ExtendedCustomBaseQueryType<GetTripDrilldownResponse>
          | ExtendedCustomBaseQueryType<TripPage>,
      ) {
        const { data, error } = await handleToggleEnable({
          customBaseQuery:
            customBaseQuery as ExtendedCustomBaseQueryType<EditActiveResponse>,
          batchId,
          body: {
            filters,
            enabled,
            from_location_id: fromLocationId,
            to_location_id: toLocationId,
            sku_id: skuId,
          },
        });

        if (error) {
          return { error };
        }

        if (data) {
          const { enabled: returnedEnabled } = data;

          const activeFiltersWithProduct = addProductToFilters({
            activeFilters: filters,
            productId,
          });

          const postResponse = await (
            customBaseQuery as ExtendedCustomBaseQueryType<GetTripDrilldownResponse>
          )({
            url: encodeUrl({
              url: `batch/{batchId}/proposal/trips/{fromLocationId}/{toLocationId}`,
              variables: { batchId, fromLocationId, toLocationId },
            }),
            method: 'POST',
            body: { filters: activeFiltersWithProduct },
          });

          if (postResponse.error) {
            return { error: postResponse.error };
          }

          // we find the product sku in the new data and update the cache
          const {
            data: { proposals: productsSkuData },
          } = postResponse;

          const foundProductSku = productsSkuData.find(
            (item) => item.sku.sku_id === skuId,
          );

          if (!foundProductSku) {
            errorSnackBar(enqueueSnackbar);
            return { error: 'Not product sku found' };
          }

          dispatch(
            tripsDrillApi.util.updateQueryData(
              'getTripsDrilldown',
              { batchId, fromLocationId, toLocationId, filters },
              (draft) => {
                const productSkuToUpdate = draft.find(
                  (item) => item.sku.sku_id === skuId,
                );

                if (productSkuToUpdate) {
                  productSkuToUpdate.enabled = returnedEnabled;
                  productSkuToUpdate.user_quantity =
                    foundProductSku.user_quantity;
                  productSkuToUpdate.to_location_all_trips_quantity =
                    foundProductSku.to_location_all_trips_quantity;
                  productSkuToUpdate.to_location_all_trips_coverage =
                    foundProductSku.to_location_all_trips_coverage;
                  productSkuToUpdate.to_location_target_coverage =
                    foundProductSku.to_location_target_coverage;
                  productSkuToUpdate.from_location_inventory_after_rebalance =
                    foundProductSku.from_location_inventory_after_rebalance;
                  productSkuToUpdate.to_location_inventory_after_rebalance =
                    foundProductSku.to_location_inventory_after_rebalance;
                  productSkuToUpdate.from_location_coverage_after_rebalance =
                    foundProductSku.from_location_coverage_after_rebalance;
                }
              },
            ),
          );

          // update the aggregated trip cache for a single trip
          await updatedAggregatedTripCache({
            customBaseQuery:
              customBaseQuery as ExtendedCustomBaseQueryType<TripPage>,
            dispatch,
            batchId,
            filters,
            fromLocationId,
            toLocationId,
            rowsPerPage,
            serverSort,
            aggregateTableSearch,
          });

          return {
            data: null,
          } as any;
        }
        return {
          data: null,
        } as any;
      },
    }),
  }),
});

export const {
  useGetTripsDrilldownQuery,
  usePatchTripDrilldownStatusMutation,
} = tripsDrillApi;

import { encodeUrl } from '@autone/ui';

import { FilterWithActive } from '../../../types';
import { rebalancingApi } from '../rebalancing';

export const filterApis = rebalancingApi.injectEndpoints({
  endpoints: (builder) => ({
    getFilters: builder.query<FilterWithActive[], { batchId: string }>({
      query: ({ batchId }) => ({
        url: encodeUrl({
          url: `/batch/{batchId}/filters`,
          variables: { batchId },
        }),
        method: 'GET',
      }),
      transformResponse: (response: { filters: FilterWithActive[] }) =>
        response.filters.map((filter) => ({
          ...filter,
          options: filter.options.map((option) => ({
            ...option,
            active: false,
          })),
        })),
    }),
  }),
});

export const { useGetFiltersQuery } = filterApis;

import { EditProposalQuantityResponse } from '@autone/openapi-rebalancing';
import {
  encodeUrl,
  errorSnackBar,
  ExtendedCustomBaseQueryType,
} from '@autone/ui';
import { enqueueSnackbar } from 'notistack';

export const handleEditQuantity = async ({
  customBaseQuery,
  body,
  batchId,
}: {
  customBaseQuery: ExtendedCustomBaseQueryType<EditProposalQuantityResponse>;
  body: {
    from_location_id: string;
    to_location_id: string;
    sku_id: string;
    quantity: number;
  };
  batchId: string;
}) => {
  const patchResponse = await customBaseQuery({
    url: encodeUrl({
      url: `batch/{batchId}/proposal/quantity`,
      variables: { batchId },
    }),
    method: 'PATCH',
    body,
    extraArgs: { hideSnackbar: true },
  });

  const { data, error } = patchResponse;

  if (error) {
    // if the error message is not from a bad transfer, we show the snackbar
    if (error.status !== 400) {
      errorSnackBar(enqueueSnackbar);
    }
  }
  return { data, error };
};

import { customBaseQuery } from '@autone/ui';
import { createApi } from '@reduxjs/toolkit/query/react';

import { EDIT_REBALANCING_TAGS } from '../../constant';

// Define a service using the base rebalancing URL and expected endpoints
export const rebalancingApi = createApi({
  reducerPath: 'rebalancingApi',
  tagTypes: [
    'Batch',
    'Batches',
    'NetworkTrips',
    'TripConfigurations',
    ...EDIT_REBALANCING_TAGS,
  ],
  baseQuery: async (args, api, extraOptions) =>
    // custom query adds auth headers and error handling
    customBaseQuery(args, api, extraOptions, 'v2/rebalancing'),
  endpoints: () => ({}),
});

import { AuthGuard, REBALANCING_PATH_APP } from '@autone/ui';
import { lazy } from 'react';

import { PageLayout } from '../layout';

const LoadingRoute = {
  guard: AuthGuard,
  layout: PageLayout,
  path: '/rebalancing/batch/:batchId/loading',
  routes: [
    {
      exact: true,
      path: REBALANCING_PATH_APP.loading,
      component: lazy(() => import('../views/BatchLoading')),
    },
  ],
};

export default LoadingRoute;

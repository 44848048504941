export const ENABLED = 'enabled';
export const PRODUCT_SIZE = 'product size';

export const OVERSTOCKED = 'excess_quantity';
export const UNDERSTOCKED = 'need_quantity';

export const RECEIVING = 'receiving';
export const SENDING = 'sending';
export const TRANSFERS_IN = 'receiving_quantity';
export const TRANSFERS_OUT = 'sending_quantity';
export const SENDING_COVERAGE_AFTER_ALL_TRANSFERS =
  'to_location_all_trips_coverage_sort';
export const RECEIVING_COVERAGE_AFTER_ALL_TRANSFERS =
  'from_location_all_trips_coverage_sort';
export const COVERAGE_AFTER_ALL_TRANSFERS = 'post_transfer_coverage_weeks';
export const LOCATION_CELL_WIDTH = 140;
export const CURRENT_UNITS = 'location_inventory';
export const TRANSFER_UNITS = 'user_quantity';
export const SENDING_CURRENT_UNITS = 'to_location_inventory';
export const RECEIVING_CURRENT_UNITS = 'from_location_inventory';
export const DUMMY_COLUMN = 'dummy_column';
export const RECEVING_LOCATION = 'receving_location';
export const SENDING_LOCATION = 'sending_location';
export const LOCATION_DETAIL_TABLE_ENDPOINTS = [
  'patchTransferQuantity',
  'patchLocationDetailStatus',
  'patchLocationDrilldownStatus',
];
